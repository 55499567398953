<template>
    <MainLayout>
        <div class="px-6 py-4 h-full flex flex-col bg-white rounded-t-3xl">
            <div>
                <h1 class="text-3xl text-gray-800 font-semibold">
                    Inicio
                </h1>
            </div>

            <div class="mt-8 space-y-2">
                <router-link 
                    :to="{ name: 'Deposit' }"
                    class="px-4 py-3 flex items-center bg-gray-100 rounded-2xl"
                >
                    <img 
                        src="@/assets/images/wallet.png"
                        class="w-16 h-16"
                    />

                    <p class="ml-6 text-xl font-bold text-gray-700">Depósito</p>
                </router-link>

                <router-link 
                    :to="{ name: 'Movements' }"
                    class="px-4 py-3 flex items-center bg-gray-100 rounded-2xl"
                >
                    <img 
                        src="@/assets/images/credit-card.png"
                        class="w-16 h-16"
                    />

                    <p class="ml-6 text-xl font-bold text-gray-700">Movimientos</p>
                </router-link>

                <router-link 
                    v-for="qr in qrs"
                    :key="qr.id"
                    :to="{ name: 'Service', params: { id: qr.id } }"
                    class="px-4 py-3 flex items-center bg-gray-100 rounded-2xl"
                >
                    <img 
                        :src="qr.image"
                        class="w-16 h-16"
                    />

                    <p class="ml-6 text-xl font-bold text-gray-700">{{ qr.name }}</p>
                </router-link>

                <router-link 
                    :to="{ name: 'Feedback' }"
                    class="px-4 py-3 flex items-center bg-gray-100 rounded-2xl"
                >
                    <img 
                        src="@/assets/images/feedback.png"
                        class="w-16 h-16"
                    />

                    <p class="ml-6 text-xl font-bold text-gray-700">Feedback</p>
                </router-link>
            </div>
        </div>
    </MainLayout>
</template>

<script>
    import MainLayout from '@/layouts/main';
    import Icon from '@/components/icon';

    import Qr from "@/api/endpoints/Qr.js";
    import usePromise from "@/composables/use-promise.js";

    export default {
        name: "Home",
        components: {
            MainLayout,
            Icon
        },
        setup() {
            // qr list
            const {
                result: qrs,
                use: getQrs,
            } = usePromise((p = 1) => {
                return Qr.all();
            });

            getQrs();


            return {
                // qrs
                qrs
            };
        },
    };
</script>